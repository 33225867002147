import React, { Component } from "react";
import { connect } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import API from "../../middleware/Api";
import { Button, IconButton, Typography, withStyles } from "@material-ui/core";
import DownloadingCard from "./DownloadingCard";
import FinishedCard from "./FinishedCard";
import RemoteDownloadButton from "../Dial/Aria2";
import Auth from "../../middleware/Auth";
import { toggleSnackbar } from "../../redux/explorer";
import Nothing from "../Placeholder/Nothing";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
    actions: {
        display: "flex",
    },
    title: {
        marginTop: "20px",
    },
    layout: {
        width: "auto",
        marginTop: "30px",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 700,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    shareTitle: {
        maxWidth: "200px",
    },
    avatarFile: {
        backgroundColor: theme.palette.primary.light,
    },
    avatarFolder: {
        backgroundColor: theme.palette.secondary.light,
    },
    gird: {
        marginTop: "30px",
    },
    hide: {
        display: "none",
    },
    loadingAnimation: {
        borderRadius: "6px 6px 0 0",
    },
    shareFix: {
        marginLeft: "20px",
    },
    loadMore: {
        textAlign: "center",
        marginTop: "20px",
        marginBottom: "20px",
    },
    margin: {
        marginTop: theme.spacing(2),
    },
});
const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackbar: (vertical, horizontal, msg, color) => {
            dispatch(toggleSnackbar(vertical, horizontal, msg, color));
        },
    };
};

class MusicDownload extends Component {
    page = 0;
    interval = 0;
    previousDownloading = -1;

    state = {
        downloading: [],
        loading: false,
        finishedList: [],
        continue: true,
    };

    componentDidMount = () => {
        this.loadDownloading();
    };

    componentWillUnmount() {
        clearTimeout(this.interval);
    }

    loadDownloading = () => {
        this.setState({
            loading: true,
        });

    };

    loadMore = () => {
        this.setState({
            loading: true,
        });
        
    };

    render() {

        return (       
        <div className="iframe-container" position="relative" padding-bottom="56.25%"> 
          <iframe src="https://pan.yintu.org/musicdl/index.html" width="100%" height="850" position = "absolute"
          top = "0" left = "0" 
          />
        </div> )  ;
    }
}

const Download = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(withTranslation()(MusicDownload)));

export default Download;
